
import { nanoid } from 'nanoid'
import {mapActions, mapGetters} from 'vuex';

export default {

  data() {
    return {
      
    }
  },
  methods: {
    ...mapActions(['getOrdersStatus']),
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        let base64, ext;
        reader.readAsDataURL(file);
        reader.onload = () => {
          base64 = reader.result;
          ext = this.getFileExt(file)
          // console.log(base64, this.getFileExt(file))
          console.log(file)
          let res = {
            ext,
            file: `${base64}`,
            name: file.name,
          }
          resolve(res);
        } 
        reader.onerror = error => reject(error);
      });

    },
    getFileExt(file) {
      let ext = file.name.split('.');
      console.log(ext)
      return ext[ext.length-1];
    },
    createItemId() {
      let newId = nanoid(8);
      return newId;
    },
    formateStatus(status) {
      // console.log(status)
      return this.ordersStatus.find(el => el.id == status.status_id).title;
    },
    formateDate(row) {
      // console.log(row);
      let oldDate = new Date(row.created_at); 
      const validDate = !isNaN(Date.parse(oldDate))
      if(validDate) {
        return oldDate.toDateString()
      }else {
        return false;
      }
    },
    formateTrueValue(row) {
      if(row.status_value == -1) {
        return this.$t('Canceled');
      }else if(row.status_value == 1) {
        return this.$t('Confirmed');
        

      }else if(row.status_value == 2) {
        return this.$t('In shipping proccess');

      }else if(row.status_value == 3) {
        return this.$t('Shipped');
      }else if(row.status_value == 4) {
        return this.$t('On the way');

      }else if(row.status_value == 5) {
        return this.$t('Deliveried');
        
      }else {
        return this.$t('Waiting');

      }
    },
    getChildCategories(categories) {
      let children = [];
      // console.log(categories)
      categories.forEach(el => {
        el.childs.forEach(child => {
          children.push(child)
        })
      })
      return children;
    },
    getRecipetTitle(status) {
      return this.recieptStatus.find(el => el.status == status).title;
    },
    resolveOrderStatusVariantAndIcon( status ) {
      // if (status === 'Partial Payment') return { variant: 'warning', icon: 'PieChartIcon' }
      if (status === 2) return { variant: 'success', icon: 'CheckIcon' }
      if (status === 6 ) return { variant: 'info', icon: 'CheckSquareIcon' }
      if (status === 1) return { variant: 'primary', icon: 'ClockIcon' }
      if (status === 5 || status === 3 || status === 4) return { variant: 'secondary', icon: 'SendIcon' }
      if (status === -1 || status === 9 || status === 8 || status === 7 ) return { variant: 'danger', icon: 'InfoIcon' }
      return { variant: 'secondary', icon: 'XIcon' }
    },
    fomateDateShort(date, reverse) {
      let dt = new Date(date);
      
      if(reverse == true) {
        dt = `${
           dt.getFullYear().toString().padStart(4, '0') }-${
           (dt.getMonth()+1).toString().padStart(2, '0') }-${
           dt.getDate().toString().padStart(2, '0')}`;
      }else {
        dt = `${ dt.getDate().toString().padStart(2, '0')}-${
           (dt.getMonth()+1).toString().padStart(2, '0') }-${
          dt.getFullYear().toString().padStart(4, '0') }`;
      }

      // console.log(dt)
      return dt;
      // console.log(this.formData.on_date)
    },
    fomateDateShort2(date) {
      let dt = new Date(date.created_at);
      dt = `${
        dt.getDate().toString().padStart(2, '0')}-${
        (dt.getMonth()+1).toString().padStart(2, '0')}-${
          dt.getFullYear().toString().padStart(4, '0')
        
        }`;

      // console.log(dt)
      return dt;
      // console.log(this.formData.on_date)
    },
    resolveRecieptStatus(status) {
      if(status == null) return { title: this.$t('Sent to supplier'), variant: 'info', icon: 'ArrowDownCircleIcon'};
      if(status == -1)  return { title: this.$t('Cancelled'), variant: 'danger', icon: 'InfoIcon'};
      if(status ==  1)  return { title: this.$t('Accepted'), variant: 'success', icon: 'CheckCircleIcon'};
      if(status ==  2)  return { title: this.$t('Done & locked'), variant: 'primary', icon: 'SaveIcon'};
      return { variant: 'secondary', icon: 'XIcon' }
    },
    openWhatsAppConve(row) {
      if(row.item.address.phone.startsWith(974)) {
        window.open(`https://wa.me/+${row.item.address.phone}`)
      }else {
        window.open(`https://wa.me/+974${row.item.address.phone}`)
      }
    },
    arDays(day) {
      
      switch (parseInt(day)) {
        case 0:
          return this.$t('sunday')
        case 1:
          return this.$t('monday') 
        case 2:
          return this.$t('tuesday')
        case 3:
          return this.$t('wednesday')
        case 4:
          return this.$t('thursday')
        // case 5:
        //   return 'الجمعة' 
        case 6:
          return this.$t('saturday')
        default:
          return day
      }
    },
    formateDayTime(item) {
      let day, time;
      if(!item.recurring.day && !item.recurring.dat) {
        day = new Date(item.created_at).toString()
        day = ` ${day.split(' ')[0]} ${day.split(' ')[2]} ${day.split(' ')[1]}`
      }else {
        let d = new Date(item.created_at)
        d.setDate(d.getDate() + (((parseInt(item.recurring.day || item.recurring.dat) + 7 - d.getDay()) % 7) || 7))
        d = d.toString()
        day = item.recurring.day || item.recurring.dat; 
        day =  ` ${d.split(' ')[0]} ${d.split(' ')[2]} ${d.split(' ')[1]}`
        
      }
      if(!item.recurring.at) {
        time = this.$t('AnyTime')
      }else {
        time = this.$t(item.recurring.at)
      }
      return `${day}   ( ${time} )`;
    },
    exportData(exportObj, params) {
      let variableParams = ``;
      let requestUrl;
      requestUrl = `https://test.optivius.com/strata/public_html/v1/admin/${exportObj}?`;
      console.log(params)
      for (const [key, value] of Object.entries(params)) {
        variableParams = `${variableParams}&${key}=${value}`
      } 
      let fixedParams =  `token=${this.user.token}&export=${1}`;
      let form = document.createElement('form');
      form.action = requestUrl + fixedParams + variableParams;
      form.method = 'POST';
      form.target = '_blank';
      document.getElementById('downloadHolder').append(form);
      form.submit();
    },
    changeFocus(id) {
      document.getElementById(id).focus()
    },
    isFileImage(file) {
      return file && file['type'].split('/')[0] === 'image';
    }
  },

  computed: {
    ...mapGetters(['ordersStatus', 'loading', 'user']),
    langs() {
      return this.$i18n.availableLocales;
    },
    locale() {
      return this.$i18n.locale;
    },
    recieptStatus() {
      return [
        {id: 0, status: null, title: this.$t('Sent to supplier'), variant: 'info', icon: 'ArrowDownCircleIcon'},
        {id: 1, status: -1, title: this.$t('Cancelled'),  variant: 'danger', icon: 'InfoIcon'},
        {id: 2, status: 1, title: this.$t('Accepted'), variant: 'success', icon: 'CheckCircleIcon'},
        {id: 3, status: 2, title: this.$t('Done & locked'), variant: 'primary', icon: 'SaveIcon'},
      ]
    },
    placeHolder() {
      return require('@/assets/images/logo/NoPath.png')
    },
    perPageOptions(){
      return [
        10, 20, 50, 100 
      ]
    } 
  }
}