import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
      redirect: {
        path: '/pages'
      }
    },
    {
      path: '/subscribtions',
      name: 'Subscribtions',
      component: () => import('@/views/ContactUs.vue'),
      meta: {
        pageTitle: 'Subscribtions',
        breadcrumb: [
          {
            text: 'Subscribtions',
            active: true,
          },
        ],
        perms:['subscribe']
      },
    },
    {
      path: '/contact-us',
      name: 'ContactUs',
      component: () => import('@/views/ContactUs.vue'),
      meta: {
        pageTitle: 'Contact Us',
        breadcrumb: [
          {
            text: 'Contact Us',
            active: true,
          },
        ],
        perms:['contactUs']
      },
    },
    {
      path: '/sections',
      name: 'Sections',
      component: () => import('@/views/Sections.vue'),
      meta: {
        pageTitle: 'Sections',
        breadcrumb: [
          {
            text: 'Sections',
            active: true,
          },
        ],
        perms:['section']
      },
    },
    {
      path: '/projects',
      name: 'Projects',
      component: () => import('@/views/Projects.vue'),
      meta: {
        pageTitle: 'Projects',
        breadcrumb: [
          {
            text: 'Projects',
            active: true,
          },
        ],
        perms:['project']
      },
    },
    {
      path: '/pages',
      name: 'Pages',
      component: () => import('@/views/Pages.vue'),
      meta: {
        pageTitle: 'Pages',
        breadcrumb: [
          {
            text: 'Pages',
            active: true,
          },
        ],
        perms:['page']
      },
    },
    {
      path: '/acounts',
      name: 'Accounts',
      component: () => import('@/views/Accounts.vue'),
      meta: {
        pageTitle: 'Accounts',
        breadcrumb: [
          {
            text: 'Accounts',
            active: true,
          },
        ],
        perms:['accounts']
      },
    },
    {
      path: '/categories',
      name: 'Categories',
      component: () => import('@/views/Categories.vue'),
      meta: {
        pageTitle: 'Categories',
        breadcrumb: [
          {
            text: 'Categories',
            active: true,
          },
        ],
        perms:['category']
      },
    },
    {
      path: '/jobs',
      name: 'Jobs',
      component: () => import('@/views/Jobs.vue'),
      meta: {
        pageTitle: 'Jobs',
        breadcrumb: [
          {
            text: 'Jobs',
            active: true,
          },
        ],
        perms:['job']
      },
    },
    {
      path: '/job-categories',
      name: 'JobCategories',
      component: () => import('@/views/JobCategories.vue'),
      meta: {
        pageTitle: 'Job Categories',
        breadcrumb: [
          {
            text: 'Job Categories',
            active: true,
          },
        ],
        perms:['jobCategory']
      },
    },
    {
      path: '/labels',
      name: 'Labels',
      component: () => import('@/views/Labels.vue'),
      meta: {
        pageTitle: 'Labels',
        breadcrumb: [
          {
            text: 'Labels',
            active: true,
          },
        ],
        perms:['label']
      },
    },
    {
      path: '/testmonials',
      name: 'Testmonials',
      component: () => import('@/views/Testmonials.vue'),
      meta: {
        pageTitle: 'Testmonials',
        breadcrumb: [
          {
            text: 'Testmonials',
            active: true,
          },
        ],
        perms:['testmonial']
      },
    },
    {
      path: '/solutions',
      name: 'Solutions',
      component: () => import('@/views/Solutions.vue'),
      meta: {
        pageTitle: 'Solutions',
        breadcrumb: [
          {
            text: 'Solutions',
            active: true,
          },
        ],
        perms:['solution']
      },
    },
    {
      path: '/sliders',
      name: 'Sliders',
      component: () => import('@/views/Sliders.vue'),
      meta: {
        pageTitle: 'Sliders',
        breadcrumb: [
          {
            text: 'Sliders',
            active: true,
          },
        ],
        perms:['slider']
      },
    },
    {
      path: '/banners',
      name: 'Banners',
      component: () => import('@/views/Banners.vue'),
      meta: {
        pageTitle: 'Banners',
        breadcrumb: [
          {
            text: 'Banners',
            active: true,
          },
        ],
        perms:['banners']
      },
    },
    {
      path: '/roles',
      name: 'Roles',
      component: () => import('@/views/Roles.vue'),
      meta: {
        pageTitle: 'Roles',
        breadcrumb: [
          {
            text: 'Roles',
            active: true,
          },
        ],
        perms: ['roles']
      },
    },
    {
      path: '/settings',
      name: 'Settings',
      component: () => import('@/views/Settings.vue'),
      meta: {
        pageTitle: 'Settings',
        breadcrumb: [
          {
            text: 'Settings',
            active: true,
          },
        ],
        perms:['settings']
      },
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: '404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },

  ],
})

router.beforeEach((to, from, next) => {
  let afterDot = [
    'settings',
    'products'
  ]
  if(to.meta.perms) {
    let userInterval = setInterval(() => {
      if(store.getters.user) {
        // console.log(store.getters.user)
        window.clearInterval(userInterval)
        let userPerms = store.getters.user.user.permissions;
        if(userPerms.includes('admin')) {
          next()
        }else {
          const intersection = userPerms.filter(element => {
            if(element.includes('products') || element.includes('settings')) {
              element = element.split('.')[1]
            }
            return to.meta.perms.find(key => element.toLowerCase().includes(key))
          });
          if(intersection.length > 0) {
            next()
          }else {
            next({
              name: '404'
            })
          }
        }
      }
    }, 100)
  }else {
    next()
  }
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
