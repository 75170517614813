import Vue from 'vue'
import Vuex from 'vuex'
import VueCryptojs from 'vue-cryptojs'


// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

import users from './users/users'
import roles from './roles/roles'
import orders from './orders/orders'
import auth from './auth/auth'
import products from './products/products'

import projects from './projects/projects'
// import categories from './categories/categories'
import testmonials from './testmonials/testmonials'
import contactus from './contactus/contactus'
import solutions from './solutions/solutions'
import jobs from './jobs/jobs'
import sections from './sections/sections'
import pages from './pages/pages'
import labels from './labels/labels'
import settings from './settings/settings'
import sliders from './sliders/sliders'
import subscribe from './subscribe/subscribe'

Vue.use(Vuex)
Vue.use(VueCryptojs)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    
    users,
    roles,
    orders,
    auth,
    products,
    // categories,
    // offers,
    projects,
    contactus,
    testmonials,
    jobs,
    sections,
    pages,
    solutions,
    labels,
    settings,
    sliders,
    subscribe,
  },
  strict: process.env.DEV,
})
