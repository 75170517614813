import Axios from '../../libs/strataIns';
import auth from '../auth/auth';
import i18n from '../../libs/i18n';
import store from '../auth/auth';

export default {
  state: {
    sections: [],
    section: {},
    
    // productsMeta: {},
    // orderStatus: {},
  },

  getters: {
    sections: (state) => state.sections,
    section: (state) => state.section,
    // productsMeta: (state) => state.productsMeta,
    // orderStatus: (state) => state.orderStatus,
  },

  mutations: {
    setSections(state, payload) {
      state.sections = [...payload]
    },
    // setProductsMeta(state, payload) {
    //   state.sections = {...payload}
    // },
    setSection(state, payload) {
      state.section = {...payload}
    },
    removeSection(state, payload) {
      let index = state.sections.findIndex(el => el.id == payload);
      state.sections.splice(index, 1);
    }

  },

  actions: {
    getSections({commit}, payload) {
      let user = store.state.user;
      let token = user ? user.token : null;
      let exporting = payload.export == 1;
      return new Promise ((resolve, reject) => {
        Axios.post(`/admin/sections`, {
          id: payload.id,
          keywords: payload.keywords,
          category_id: payload.category_id,
          status: payload.status,
          page: payload.page,
          load_categories: payload.load_categories,
          export: payload.export,
          sort_by: payload.sort_by,
          sort_as: payload.sort_as,
          limit: payload.limit,
        },)
        .then(res => {
          if(exporting) {
            resolve(res)
          }
          if(res.data.results != null) {
            commit('setSections', res.data.results);
            // commit('setProductsMeta', res.data.meta);
            resolve(res);
          }else {
            reject(res)
          }
        }).catch(err => {
          reject(err)
        });
      })
    }, 
    getSection({commit}, payload) {
      return new Promise ((resolve, reject) => {
        Axios.post(`/admin/sections/show/${payload}`, {
        })
        .then(res => {
          if(res.data.results != null) {
            commit('setSection', res.data.results);
            resolve(res);
          }else {
            reject(res)
          }
        }).catch(err => {
          reject(err)
        });
      })
    },
    createSection({commit, state}, payload) {
      console.log(payload)
      let fd = new FormData();
      if(payload.id) {
        fd.append('id', payload.id);
      }
      if(payload.style && payload.style.type) {
        fd.append('style[type]', payload.style.type);
      }
      fd.append('titles[ar]', payload.titles.ar);
      fd.append('titles[en]', payload.titles.ar);
      fd.append('descriptions[ar]', payload.descriptions.ar);
      fd.append('descriptions[en]', payload.descriptions.ar);
      fd.append('status', payload.status == true ? 1 : 0);
      fd.append('page_id', parseInt(payload.page_id));
      fd.append('ord', payload.ord);
      if(payload.logo1 || payload.logo2) {
        fd.append('labels[]', payload.logo1.id ? payload.logo1.id : payload.logo1);
        fd.append('labels[]', payload.logo2.id ? payload.logo2.id : payload.logo2);
      }
      // fd.append('style', payload.style);
      // fd.append('action_button', payload.action_button);
      if(payload.custom_fields) {
        fd.append('custom_fields[count1]', payload.custom_fields.count1 || 0);
        fd.append('custom_fields[count2]', payload.custom_fields.count2 || 0);
        fd.append('custom_fields[count3]', payload.custom_fields.count3 || 0);
        fd.append('custom_fields[count4]', payload.custom_fields.count4 || 0);
        fd.append('custom_fields[count5]', payload.custom_fields.count5 || 0);
        
      }
      if(payload.cover_size) fd.append('custom_fields[cover_size]', payload.cover_size);
      if(payload.logo1) fd.append('custom_fields[logo1]', payload.logo1);
      if(payload.logo2) fd.append('custom_fields[logo2]', payload.logo2);
      
      if(payload.slider) fd.append('custom_fields[slider_id]', payload.slider.id);
      if(payload.slider) fd.append('custom_fields[key]', payload.slider.key);
      
      if(payload.base64 && payload.base64 != null) {
        fd.append('media_type', payload.media_type || 0);
        fd.append('media', payload.base64);
      }

      let data = {
        id: payload.id,
        titles: payload.titles,
        descriptions: payload.descriptions,
        status: payload.status,
        featured: payload.featured,
        categorys_id: payload.categorys_id,
        items: payload.items,
        // "items": [
        //     {
        //         "id": "xa123",
        //         "price": 12.5,
        //         "quantity": 42,
        //         "titles": {
        //             "en": "item 1",
        //             "ar": "صنف 1"
        //         }
        //     }
        // ],
        stepper: payload.stepper,
        images: payload.base64 
      }
      return new Promise ((resolve, reject) => {
        Axios.post(`/admin/sections/save`,  fd,{
          headers: {
            'content-type': 'multipart/form-data',
          }
        })
        .then(res => {
          if(res.data.results != null) {
            resolve(res);
          }else {
            reject(res)
          }
        }).catch(err => {
          reject(err)
        });
      })
    }, 
    deleteSection({commit}, payload) {
      return new Promise ((resolve, reject) => {
        Axios.post(`admin/sections/delete/${payload}`, {})
        .then(res => {
          if(res.data.results != null) {
            commit('removeSection', payload);
            resolve(res);
          }else {
            reject(res)
          }
        }).catch(err => {
          reject(err)
        });
      })
    }, 
  },
  modules: {
    // auth     
  }
}