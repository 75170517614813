import Axios from '../../libs/strataIns';

export default {
  state: {
    projects: [],
    project: {},
    
    categories: [],
    category: {},
  },

  getters: {
    projects: (state) => state.projects,
    project: (state) => state.project,
    categories: (state) => state.categories,
    category: (state) => state.category,
  },

  mutations: {
    setProjects(state, payload) {
      state.projects = [...payload]
    },
    setCategories(state, payload) {
      state.categories = [...payload]
    },
    setCategory(state, payload) {
      state.category = {...payload}
    },    
    setProject(state, payload) {
      state.project = {...payload}
    },    
    removeProject(state, payload) {
      let index = state.projects.findIndex(el => el.id == payload);
      state.projects.splice(index, 1); 
    },
    removeCategory(state, payload) {
      let index = state.categories.findIndex(el => el.id == payload);
      state.categories.splice(index, 1);
    }

  },

  actions: {
    getCategories({commit}, payload) {
      return new Promise ((resolve, reject) => {
        Axios.post(`/admin/categories`, {
          keywords: payload.keywords,
          page: payload.page,
        })
        .then(res => {
          if(res.data.results != null) {
            commit('setCategories', res.data.results);
            resolve(res);
          }else {
            reject(res)
          }
        }).catch(err => {
          reject(err)
        });
      })
    }, 
    getProjects({commit}, payload) {
      return new Promise ((resolve, reject) => {
        Axios.post(`/admin/projects`, {
          keywords: payload.keywords,
          page: payload.page,
          category_id: payload.category_id
        })
        .then(res => {
          if(res.data.results != null) {
            commit('setProjects', res.data.results);
            resolve(res);
          }else {
            reject(res)
          }
        }).catch(err => {
          reject(err)
        });
      })
    }, 
    getProject({commit}, payload) {
      return new Promise ((resolve, reject) => {
        Axios.post(`/admin/projects/show/${payload}`, {
        })
        .then(res => {
          if(res.data.results != null) {
            commit('setProject', res.data.results);
            resolve(res);
          }else {
            reject(res)
          }
        }).catch(err => {
          reject(err)
        });
      })
    },
    getCategory({commit}, payload) {
      return new Promise ((resolve, reject) => {
        Axios.post(`/admin/categories/show/${payload}`, {
        })
        .then(res => {
          if(res.data.results != null) {
            commit('setCategory', res.data.results);
            resolve(res);
          }else {
            reject(res)
          }
        }).catch(err => {
          reject(err)
        });
      })
    },
    createCategory({commit}, payload) {
      return new Promise ((resolve, reject) => { 
        Axios.post(`/admin/categories/save`, {
          id: payload.id,
          titles: {
            ar: payload.titles.ar,
            en: payload.titles.ar,
          }, 
          descriptions: {
            ar: payload.titles.ar,
            en: payload.titles.ar,
          }, 
          parent_id: payload.parent_id,
          status: payload.status,
        })
        .then(res => {
          if(res.data.results != null) {
            if(payload.id) {
              commit('updateCategory', res.data.results);
            }else {
              // commit('addCategory', res.data.results);
            }
            resolve(res);
          }else {
            reject(res)
          }
        }).catch(err => {
          reject(err)
        });
      })
    }, 
    createProject({commit}, payload) {
      console.log(payload.deleteFeatured)
      return new Promise ((resolve, reject) => {
        let labels = [];
        if(payload.label1) {
          payload.label1.id ?
          labels.push(payload.label1.id) :
          labels.push(payload.label1);
        }
        if(payload.label2) {
          payload.label2.id ? 
          labels.push(payload.label2.id) :
          labels.push(payload.label2);
        }
        console.log(payload, labels)        
        Axios.post(`/admin/projects/save`, {
          id: payload.id,
          title: {
            ar: payload.titles.ar,
            en: payload.titles.ar,
          }, 
          description: {
            ar: payload.descriptions.ar,
            en: payload.descriptions.ar
          }, 
          category_id: payload.category_id,
          solution_id: payload.solution_id,
          labels: labels,
          status: payload.status,
          client_name: payload.client_name,
          cost: payload.cost,
          duration: payload.duration,
          ord: payload.ord,
          images: payload.base64,
          featured_image: payload.deleteFeatured,
          featured_image_base64: payload.featuredImg,
        })
        .then(res => {
          if(res.data.results != null) {
            if(payload.id) {
              commit('updateProject', res.data.results);
            }else {
              commit('addProject', res.data.results);
            }
            resolve(res);
          }else {
            reject(res)
          }
        }).catch(err => {
          reject(err)
        });
      })
    }, 
    deleteCategory({commit}, payload) {
      return new Promise ((resolve, reject) => {
        Axios.post(`/admin/categories/delete/${payload}`, {})
        .then(res => {
          if(res.data.results != null) {
            commit('removeCategory', payload);
            resolve(res);
          }else {
            reject(res)
        }
        }).catch(err => {
          reject(err)
        });
      })
    }, 
    deleteProject({commit}, payload) {
      return new Promise ((resolve, reject) => {
        Axios.post(`/admin/projects/delete/${payload}`, {})
        .then(res => {
          if(res.data.results != null) {
            commit('removeProject', payload);
            resolve(res);
          }else {
            reject(res)
          }
        }).catch(err => {
          reject(err)
        });
      })
    }, 
    
  }
}